<template>
<div class="fit sticky-form-actions">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless :style="'overflow-y: auto; height: 100vh; padding-bottom: ' + formActionsRowHeight + 'px; '">
        <b-tab class="p-0">
            <template slot="title">
                <div class="mt-1"><q-icon name="fa fa-calendar-plus" class="mr-1" />{{ translate('add_appointment') }}</div>
            </template>
            <b-list-group class="list-group-accent">
                <b-form @submit.prevent>
                    <b-list-group-item class="p-0 pb-2">
                        <b-list-group-item>
                            <div class="row m-0 text-muted align-items-center">
                                <div class="col-2 col-md-auto pl-0 text-center"><i class="fas fa-swimmer fa-2x"></i></div>
                                <div class="col-10 col-md">
                                    <small>{{ translate('service') }}</small><br />
                                    <strong>{{ service.name }}</strong>
                                </div>
                                <div class="col-2 col-md-auto pl-0 text-center"><i class="fas fa-map-marker-alt fa-2x"></i></div>
                                <div class="col-10 col-md">
                                    <small>{{ translate('location') }}</small><br />
                                    <strong>{{ location.name }}</strong>
                                </div>
                            </div>
                        </b-list-group-item>
                        <!-- [+] Single Select Custom Component. -->
                        <include-single-select :with_image="true" :no_add_button="true" item_ref="workers_ref" :placeholder_find_item="'find_worker'" :entity_type="'workers'" :field_name="'consultant'" tooltip_text="create_worker" :get_route_to_include="get_providers_route" :get_route_extra_query="get_providers_route_extra_query" />
                        <!-- [-] Single Select Custom Component. -->
                        <div class="row m-0">
                            <div class="col-md p-0">
                                <q-input :label="translate('customer_name')" square dense color="primary" :placeholder="translate('enter_customer_name')" :readonly="Boolean(customer_name)" type="text" v-model="appointments.customer_name" />
                            </div>
                            <div class="col-md p-0">
                                <q-input :label="translate('date')" square dense readonly color="primary" id="date" v-model="appointments.date">
                                    <template v-slot:append>
                                        <q-icon name="event"></q-icon>
                                    </template>
                                </q-input>
                            </div>
                        </div>
                        <div class="row m-0">
                            <div class="col p-0">
                                <q-input :label="translate('start_time')" square dense color="primary" v-model="appointments.start_time" emit-value>
                                    <template v-slot:append>
                                        <q-icon name="access_time" class="cursor-pointer">
                                            <q-popup-proxy transition-show="scale" transition-hide="scale">
                                                <q-time class="time-menu" v-model="appointments.start_time" mask="HH:mm" format24h />
                                            </q-popup-proxy>
                                        </q-icon>
                                    </template>
                                </q-input>
                            </div>
                            <div class="col p-0">
                                <q-input :label="translate('end_time')" square dense color="primary" v-model="appointments.end_time" emit-value>
                                    <template v-slot:append>
                                        <q-icon name="access_time" class="cursor-pointer">
                                            <q-popup-proxy transition-show="scale" transition-hide="scale">
                                                <q-time class="time-menu" v-model="appointments.end_time" mask="HH:mm" format24h />
                                            </q-popup-proxy>
                                        </q-icon>
                                    </template>
                                </q-input>
                            </div>
                        </div>
                    </b-list-group-item>
                </b-form>
                <div class="form-actions bottom bg-light" :style="'width: ' + slideoutTabsWidth + 'px'">
                    <q-item class="p-3">
                        <q-item-section side>
                            <q-btn type="submit" no-caps color="info" @click.prevent="registerAppointment">
                                <q-icon size="1rem" name="check" class="mr-1" /> {{ translate('save') }}
                            </q-btn>
                        </q-item-section>
                        <q-item-section></q-item-section>
                        <q-item-section side>
                            <q-btn @click.prevent="closePanel" type="button" no-caps color="dark">
                                <q-icon size="1rem" name="close" class="mr-1" /> {{ translate('cancel') }}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </div>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import _ from 'lodash'
import {
    eventBus
} from "../../../../main"
import {
    QTime,
    QDate
} from 'quasar'
import * as jwt_decode from 'jwt-decode'
import IncludeSingleSelect from '../../../data-libraries/template-parts/forms/IncludeSingleSelect'
import OverlapDetails from '../../../template-parts/OverlapDetails'

export default {
    name: 'AddAppointment',
    props: ['service', 'location', 'event', 'customer_name', 'customer_id'],
    components: {
        QTime,
        QDate,
        'include-single-select': IncludeSingleSelect,
    },
    computed: {
        get_providers_route: function () {
            return baseUrl + 'services/providers'
        },
        get_providers_route_extra_query: function () {
            return '&service_id=' + this.service.id
        }
    },
    created: function () {
        this.setDefaultDate()
        this.setDefaultTime()
        this.getServiceInfo()
        this.getElementsWidthHeight()
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.getElementsWidthHeight)
    },
    mounted: function () {
        window.addEventListener('resize', this.getElementsWidthHeight)
        eventBus.$on('update_selected_single_choice_id_workers_consultant', (item) => {
            this.updateSelectedWorkerId(item)
        })
    },
    data: function() {
        return {
            formActionsRowHeight: 0,
            slideoutTabsWidth: 0,

            worker_id: null,
            provider_options: [],
            appointments: {
                customer_id: this.customer_id ? this.customer_id : 0,
                customer_name: this.customer_name ? this.customer_name : null,
                date: '',
                start_time: '',
                end_time: '',
            },
            msg: '',
        };
    },
    methods: {
        updateSelectedWorkerId: function (item) {
            if (item && item.worker_id) {
                this.worker_id = item.worker_id
            }
        },
        showOverlapPanel: function (data) {
            const panelInstance = this.$showPanel({
                component: OverlapDetails,
                props: {
                    data: data
                }
            })
        }, 
        getElementsWidthHeight: _.debounce(function () {
            this.formActionsRowHeight = document.querySelector('.form-actions').clientHeight
            this.slideoutTabsWidth = document.querySelector('.slideout .tabs').clientWidth
        }, 200),
        setSelectableEndDays: function(date) {
            var dayWeekIndex = new Date(date).getDay()
            return date => new Date(date).getDay() === dayWeekIndex
        },
        setDefaultDate: function () {
            this.appointments.date = this.event.date
        },
        getServiceInfo: function () {
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            };

            axios.get(baseUrl + 'services/' + this.service.id, {
                    headers: headers
                })
                .then(response => {
                    var service = response.data.item
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        setDefaultTime: function () {
            var subtime = ':00'
            if (this.event.minute > 30) {
                subtime = ':30'
            }
            var meridian = ' AM'
            if (this.hour >= 12) {
                meridian = ' PM'
            }
            meridian = ''
            var event_hour = parseInt(this.event.hour)
            var event_start_hour = event_hour < 10 ? '0' + event_hour : event_hour
            var event_end_hour = (event_hour + 1) < 10 ? '0' + (event_hour + 1) : (event_hour + 1)

            this.appointments.start_time = event_start_hour + subtime + meridian
            this.appointments.end_time = event_end_hour + subtime + meridian
        },
        workerId: function () {
            this.token = localStorage.getItem('access_token')
            var decoded = jwt_decode(this.token)
            var id = 0
            if (decoded.identity.worker_id) {
                id = decoded.identity.worker_id
            }
            return id
        },
        registerAppointment: function () {
            //temp - need to abort if accessToken() is null
            if (this.accessToken() == null) {
                return this.$router.push({
                    name: 'Login'
                })
            }

            let workerId = this.workerId()
            let selected_worker_id = this.worker_id ? this.worker_id : workerId

            var data = {
                'customer_id': this.appointments.customer_id,
                'customer_name': this.appointments.customer_name,
                'service_id': this.service.id,
                'location_id': this.location.id,
                'date': this.appointments.date,
                'start_time': this.appointments.start_time + ':00',
                'end_time': this.appointments.end_time + ':00',
                'worker_id': selected_worker_id,
            }
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            };

            axios.post(baseUrl + 'appointments', data, {
                    headers: headers
                })
                .then(response => {
                    this.msg = response.data.msg;
                    if (response.data.status) {
                        var message = 'add_appointment_' + this.msg
                        var translated_message = this.translate(message)
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        this.$emit('closePanel', {
                            status: response.data.status
                        });
                    } else {
                        var key = response.data.key ? '_' + response.data.key : ''
                        var message = 'add_appointment_' + this.msg + key
                        var translated_message = this.translate(message)

                        if (response.data.msg && _.endsWith(response.data.msg, '_overlap')) {
                            // open new panel with overlap details displayed.
                            this.showOverlapPanel(response.data)
                        } else {
                            this.$toasted.error(translated_message, {
                                duration: this.$toasted_duration
                            })
                        }
                    }

                })
                .catch(error => {
                    var msg = error.response.data.msg;
                    if (msg != undefined) {
                        this.msg = msg;
                        this.$toasted.error('An error has occured', {
                            duration: this.$toasted_duration
                        })
                    }

                })
        },
        closePanel() {
            this.$emit('closePanel', {
                response: 'dsfd'
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.card_scroll {
    position: relative;
    max-height: 300px;
    overflow-y: auto;
}

.q-item.list-item {
    background: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgba(0, 0, 0, .16);
    margin: .5rem 0;
    transition: border-color .1s ease-in-out;

    &:hover {
        border-color: rgba(0, 0, 0, .26);
    }

    &[disabled="disabled"] {
        border-color: rgba(0, 0, 0, .2);
        background: rgba(0, 0, 0, .2);
    }
}
</style>
