<template>
<div class="fit sticky-form-actions">
    <q-btn class="close-panel right top position-absolute" color="grey-8" @click.prevent="closePanel" type="button" icon="close" size="12px" flat dense />
    <b-tabs borderless :style="'overflow-y: auto; height: 100vh; padding-bottom: ' + formActionsRowHeight + 'px; '">
        <b-tab class="p-0">
            <template slot="title">
                <div class="mt-1"><q-icon name="edit" class="mr-1" />{{ translate('edit_appointment') }}</div>
            </template>
            <b-list-group class="list-group-accent">
                <b-form @submit.prevent="registerAppointment" style="overflow: hidden;">
                    <b-list-group-item class="p-0">
                        <div class="row m-0" v-if="appointment.customer_name || appointment.worker_name">
                            <div class="col-md p-0">
                                <q-item class="full-width">
                                    <q-item-section class="align-content-center align-items-center col-1 text-muted" side>
                                        <q-avatar class="shadow-2">
                                            <img v-if="appointment.customer_image_link" :src="appointment.customer_image_link">
                                            <i v-else class="fas fa-user fa-1x text-muted"></i>
                                        </q-avatar>
                                    </q-item-section>
                                    <q-item-section class="col-11">
                                        <small>{{ translate('customer') }}</small>
                                        <strong>{{ appointment.customer_name }}</strong>
                                    </q-item-section>
                                </q-item>
                            </div>
                            <div class="col-md p-0">
                                <q-item class="full-width">
                                    <q-item-section class="align-content-center align-items-center col-1 text-muted" side>
                                        <q-avatar class="shadow-2">
                                            <img v-if="appointment.worker_image_link" :src="appointment.worker_image_link">
                                            <i v-else class="fas fa-user-cog fa-1x text-muted"></i>
                                        </q-avatar>
                                    </q-item-section>
                                    <q-item-section class="col-11">
                                        <small>{{ translate('consultant') }}</small>
                                        <strong>{{ appointment.worker_name }}</strong>
                                    </q-item-section>
                                </q-item>
                            </div>
                        </div>
                        <div class="row m-0" v-if="appointment.creator_name || appointment.created_at">
                            <div class="col-md p-0">
                                <q-item class="full-width">
                                    <q-item-section class="align-content-center align-items-center col-1 text-muted" side>
                                        <q-avatar class="shadow-2">
                                            <img v-if="appointment.creator_image_link" :src="appointment.creator_image_link">
                                            <i v-else class="fas fa-user-cog fa-1x text-muted"></i>
                                        </q-avatar>
                                    </q-item-section>
                                    <q-item-section class="col-11">
                                        <small>{{ translate('created_by') }}</small>
                                        <strong>{{ appointment.creator_name }}</strong>
                                    </q-item-section>
                                </q-item>
                            </div>
                            <div class="col-md p-0">
                                <q-item class="full-width">
                                    <q-item-section class="align-content-center align-items-center col-1 text-muted" side>
                                        <q-icon name="fas fa-calendar-check" size="2rem"></q-icon>
                                    </q-item-section>
                                    <q-item-section class="col-11">
                                        <small>{{ translate('created_at') }}</small>
                                        <strong>{{ prettyDate(appointment.created_at) }}</strong>
                                    </q-item-section>
                                </q-item>
                            </div>
                        </div>
                        <div class="row m-0" v-if="service.name || location.name">
                            <div class="col-md p-0">
                                <q-item class="full-width">
                                    <q-item-section class="align-content-center align-items-center col-1 text-muted" side>
                                        <q-avatar class="shadow-2">
                                            <i class="fas fa-swimmer fa-1x text-muted"></i>
                                        </q-avatar>
                                    </q-item-section>
                                    <q-item-section class="col-11">
                                        <small>{{ translate('service') }}</small>
                                        <strong>{{ service.name }}</strong>
                                    </q-item-section>
                                </q-item>
                            </div>
                            <div class="col-md p-0">
                                <q-item class="full-width">
                                    <q-item-section class="align-content-center align-items-center col-1 text-muted" side>
                                        <q-icon name="fas fa-map-marker-alt" size="2rem"></q-icon>
                                    </q-item-section>
                                    <q-item-section class="col-11">
                                        <small>{{ translate('location') }}</small>
                                        <strong>{{ location.name }}</strong>
                                    </q-item-section>
                                </q-item>
                            </div>
                        </div>
                        <b-list-group-item class="p-0">
                            <q-input :label="translate('date')" square dense color="primary" class="full-width" readonly id="date" v-model="appointment.date">
                                <template v-slot:append>
                                    <q-icon name="event"></q-icon>
                                </template>
                            </q-input>
                        </b-list-group-item>
                        <div class="row m-0">
                            <div class="col p-0">
                                <q-input :label="translate('start_time')" square dense color="primary" class="full-width" readonly v-model="appointment.start_time" emit-value>
                                    <template v-slot:append>
                                        <q-icon name="access_time"></q-icon>
                                    </template>
                                </q-input>
                            </div>
                            <div class="col p-0">
                                <q-input :label="translate('end_time')" square dense color="primary" class="full-width" readonly v-model="appointment.end_time" emit-value>
                                    <template v-slot:append>
                                        <q-icon name="access_time"></q-icon>
                                    </template>
                                </q-input>
                            </div>
                        </div>
                    </b-list-group-item>
                </b-form>
                <div class="form-actions bottom bg-light" :style="'width: ' + slideoutTabsWidth + 'px'">
                    <q-item class="p-3">
                        <q-item-section side :class="$q.screen.lt.sm ? 'p-0' : ''">
                            <q-btn @click.prevent="showDeleteAppointmentPanel" type="button" no-caps color="danger">
                                <q-icon size="1rem" name="delete" class="mr-1" /> {{ translate('delete_appointment') }}
                            </q-btn>
                        </q-item-section>
                        <q-item-section></q-item-section>
                        <q-item-section side :class="$q.screen.lt.sm ? 'p-0' : ''">
                            <q-btn @click.prevent="closePanel" type="button" no-caps color="dark">
                                <q-icon size="1rem" name="close" class="mr-1" /> {{ translate('cancel') }}
                            </q-btn>
                        </q-item-section>
                    </q-item>
                </div>
            </b-list-group>
        </b-tab>
    </b-tabs>
</div>
</template>

<script>
import _ from 'lodash'
import {
    QTime,
    QDate
} from 'quasar'
import DeleteAppointment from '../forms/DeleteAppointment'

export default {
    name: 'EditAppointment',
    props: ['service', 'location', 'event'],
    components: {
        QTime,
        QDate
    },
    created() {
        this.getElementsWidthHeight()
        this.setInitialValues()
        this.getAppointment()
    },
    beforeDestroy: function () {
        window.removeEventListener('resize', this.getElementsWidthHeight)
    },
    mounted: function () {
        window.addEventListener('resize', this.getElementsWidthHeight)
    },
    data: function() {
        return {
            formActionsRowHeight: 0,
            slideoutTabsWidth: 0,

            appointment: {
                is_active: true,
                date: '',
                start_time: '',
                end_time: '',
                appointment_id: 0,
                customer_name: ''
            },
            msg: '',
        }
    },
    methods: {
        getElementsWidthHeight: _.debounce(function () {
            this.formActionsRowHeight = document.querySelector('.form-actions').clientHeight
            this.slideoutTabsWidth = document.querySelector('.slideout .tabs').clientWidth
        }, 200),
        getAppointment: function () {
            var url = baseUrl + 'appointments/' + this.event.appointment_id
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }

            axios.get(url, {
                    headers: headers
                })
                .then(response => {
                    this.appointment = response.data.item
                    this.appointment.creator_image_link = response.data.item.creator_image_link ? baseUrl + response.data.item.creator_image_link : null
                    this.appointment.worker_image_link = response.data.item.worker_image_link ? baseUrl + response.data.item.worker_image_link : null
                    this.appointment.customer_image_link = response.data.item.customer_image_link ? baseUrl + response.data.item.customer_image_link : null
                }).catch(error => {
                    var msg = error.response.data.msg
                    if (msg != undefined) {
                        this.msg = msg
                        this.$toasted.error('An error has occured', {
                            duration: this.$toasted_duration
                        })
                    }

                })
        },
        setSelectableEndDays: function(date) {
            var dayWeekIndex = new Date(date).getDay()
            return date => new Date(date).getDay() === dayWeekIndex
        },
        setInitialValues: function () {
            this.appointment.date = this.event.date
            this.appointment.start_time = this.event.start_time
            this.appointment.end_time = this.event.end_time
            this.appointment.appointment_id = this.event.appointment_id
        },
        registerAppointment: function () {
            if (this.accessToken() == null) {
                return this.$router.push({
                    name: 'Login'
                })
            }

            var data = {
                'date': this.appointment.date
            }
            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
                'Content-Type': 'application/json'
            }

            axios.put(baseUrl + 'appointments/' + this.appointment.appointment_id, data, {
                    headers: headers
                })
                .then(response => {
                    this.msg = response.data.msg
                    var key = response.data.key ? '_' + response.data.key : ''
                    var message = 'add_appointment_' + this.msg + key
                    var translated_message = this.translate(message)

                    if (response.data.status) {
                        this.$toasted.success(translated_message, {
                            duration: this.$toasted_duration
                        })
                        this.$emit('closePanel', {
                            status: response.data.status
                        })
                    } else {
                        this.$toasted.error(translated_message, {
                            duration: this.$toasted_duration
                        })
                    }
                })
                .catch(error => {
                    var msg = error.response.data.msg
                    if (msg != undefined) {
                        this.msg = msg
                        this.$toasted.error('An error has occured', {
                            duration: this.$toasted_duration
                        })
                    }
                })
        },
        showDeleteAppointmentPanel: function () {
            const panelInstance = this.$showPanel({
                component: DeleteAppointment,
                props: {
                    appointment_id:  this.event.appointment_id
                }
            })

            panelInstance.promise
                .then(result => {
                    if (result && result.status) {
                        this.$emit('closePanel', {
                            status: true
                        }) 
                        // Fix issue from slideout closing panel.
                        document.querySelector('body').classList.remove('slideout-panel-open')
                    }
                })
        },
        closePanel: function () {
            this.$emit('closePanel')
        }
    }
}
</script>

<style lang="scss" scoped>
.card_scroll {
    position: relative;
    max-height: 300px;
    overflow-y: auto;
}
</style>
