<template>
<div class="fit">
    <b-card class="m-0 pb-0" no-body no-header>
        <div class="card-header bg-white">
            <q-item class="m-0 p-0" style="min-height: 0">
                <q-item-section side>
                    <h3 class="text-dark m-0">{{ translate('appointments') }}</h3>
                </q-item-section>
                <q-item-section side v-if="!$q.screen.lt.sm" class="align-items-stretch p-0">
                    <q-item class="p-0 selected-badge full-height" dark>
                        <template v-if="customer_name">
                            <q-item-section side class="p-0 pl-2 text-center selected-badge-section bg-light">
                                <i class="fas fa-user fa-sm text-green"></i>
                            </q-item-section>
                            <q-item-section side class="p-0 pr-2 text-center bg-light">
                                <q-badge class="no-border-radius full-width p-1 text-green font-weight-bold" color="light">{{ customer_name }}</q-badge>
                            </q-item-section>
                        </template>
                        <template v-if="selected_service_card">
                            <q-item-section side class="p-0 pl-2 text-center selected-badge-section bg-light">
                                <i class="fas fa-swimmer fa-sm text-blue-6"></i>
                            </q-item-section>
                            <q-item-section side class="p-0 pr-2 text-center bg-light">
                                <q-badge class="no-border-radius full-width p-1 text-blue-6 font-weight-bold" color="light">{{ selected_service.name }}</q-badge>
                            </q-item-section>
                        </template>
                        <template v-if="selected_location_card">
                            <q-item-section side class="p-0 pl-2 text-center selected-badge-section bg-light">
                                <i class="fas fa-map-marker-alt fa-sm text-indigo-6"></i>
                            </q-item-section>
                            <q-item-section side class="p-0 pr-2 text-center bg-light">
                                <q-badge class="no-border-radius full-width p-1 text-indigo-6 font-weight-bold" color="light">{{ selected_location.name }}</q-badge>
                            </q-item-section>
                        </template>
                    </q-item>
                </q-item-section>
                <q-space />
                <q-item-section class="m-0 p-0" side>
                    <q-btn round v-if="selected_service_card" :icon="show_cards ? 'fa fa-chevron-up' : 'fa fa-chevron-down'" @click.stop.prevent="show_cards=!show_cards" flat dense color="primary" />
                </q-item-section>
            </q-item>
        </div>
        <template v-if="$q.screen.lt.sm">
            <q-item v-if="customer_name" class="p-0 bg-light selected-badge" dark>
                <q-item-section side class="pl-2 pr-0 text-center selected-badge-section">
                    <i class="fas fa-user fa-sm text-green"></i>
                </q-item-section>
                <q-item-section side>
                    <q-badge class="no-border-radius full-width p-1 text-green font-weight-bold" color="light">{{ customer_name }}</q-badge>
                </q-item-section>
            </q-item>
            <q-item v-if="selected_service_card" class="p-0 bg-light selected-badge" dark>
                <q-item-section side class="pl-2 pr-0 text-center selected-badge-section">
                    <i class="fas fa-swimmer fa-sm text-blue-6"></i>
                </q-item-section>
                <q-item-section side>
                    <q-badge class="no-border-radius full-width p-1 text-blue-6 font-weight-bold" color="light">{{ selected_service.name }}</q-badge>
                </q-item-section>
            </q-item>
            <q-item v-if="selected_location_card" class="p-0 bg-light selected-badge" dark>
                <q-item-section side class="pl-2 pr-0 text-center selected-badge-section">
                    <i class="fas fa-map-marker-alt fa-sm text-indigo-6"></i>
                </q-item-section>
                <q-item-section side>
                    <q-badge class="no-border-radius full-width p-1 text-indigo-6 font-weight-bold" color="light">{{ selected_location.name }}</q-badge>
                </q-item-section>
            </q-item>
        </template>
        <choose-customer :class="show_cards === false ? 'hidden' : ''" @updateCustomer="updateCustomer" />
    </b-card>
    <div :class="show_cards === false ? 'hidden' : ''">
        <div class="service-cards">
            <div class="calendar-search-wrapper col-xs-12 p-2 m-0 d-flex align-items-center justify-content-center">
                <q-input class="search-input m-0 bg-white" @input="isTypingAppointmentService = true" v-model="searchAppointmentService" id="filternameAppointmentService" type="text" :placeholder="translate('find_service')" :dense="true">
                    <template v-slot:prepend>
                        <q-icon name="search" />
                    </template>
                    <template v-slot:append>
                        <q-btn type="button" class="mr-1" @click.stop="showAddServicePanel" flat dense color="dark" no-caps :label="translate('new_service')" icon="add" />
                    </template>
                </q-input>
            </div>
            <div class="row m-0 mb-3 list_wrapper-appointment-calendar-services horizontal-list">
                <div v-for="(service,i) in services" :key="'servicew-' + i + '-' + service.id" :id="'servicew-' + i + '-' + service.id" class="p-2 mt-2 list-item">
                    <q-card :class="selected_service_card === service.id ? 'selected-horizontal-card' : ''" @click="setSelectedService(service)" clickable v-ripple class="full-height cursor-pointer" bordered rounded>
                        <template v-if="service.main_image_link">
                            <q-carousel height="90px" class="product-main-image" v-model="slide">
                                <q-carousel-slide :name="1" class="p-0">
                                    <img :src="baseUrl + service.main_image_link" class="slide-main-image" />
                                </q-carousel-slide>
                            </q-carousel>
                        </template>
                        <template v-else>
                            <q-carousel height="90px" class="product-main-image no-image" v-model="slide">
                                <q-carousel-slide :name="1">
                                    <div class="absolute-center custom-caption text-center">
                                        <q-icon name="image" />
                                    </div>
                                </q-carousel-slide>
                            </q-carousel>
                        </template>
                        <q-card-section class="font-weight-light small pt-2 pb-2 pl-1 pr-1 line-height-1 text-center">
                            <strong>{{ service.name }}</strong>
                        </q-card-section>
                    </q-card>
                </div>
                <b-card v-if="services && services.length === 0" no-header class="full-width p-0 mt-2 text-muted">
                    {{ translate(no_services_found_message) }}
                </b-card>
            </div>
            <infinite-loading slot="append" :identifier="'appointments-infServices-' + infServices" @infinite="getServices" />
        </div>
        <div v-if="showAllLocations" class="location-cards">
            <q-separator v-if="service_locations.length" />
            <div class="calendar-search-wrapper col-xs-12 p-2 pt-md-3 mt-1 mt-md-0 d-flex align-items-center justify-content-center">
                <q-input class="search-input m-0 bg-white" @input="isTypingAppointmentServiceLocation = true" v-model="searchAppointmentServiceLocation" id="filternameAppointmentServiceLocation" type="text" :placeholder="translate('find_location')" :dense="true">
                    <template v-slot:prepend>
                        <q-icon name="search" />
                    </template>
                    <template v-slot:append>
                        <q-btn type="button" class="mr-1" @click.stop="showAddLocationPanel" flat dense color="dark" no-caps :label="translate('new_location')" icon="add" />
                    </template>
                </q-input>
            </div>
            <div class="row m-0 mb-3 list_wrapper-all-locations horizontal-list">
                <div v-for="(location,i) in service_locations" :key="'locationw-' + i + '-' + location.id" :id="'locationw-' + i + '-' + location.id" class="p-2 mt-2 list-item">
                    <q-card :class="selected_location_card === location.id ? 'selected-horizontal-card' : ''" @click="setSelectedLocation(location)" clickable v-ripple class="full-height cursor-pointer" bordered rounded>
                        <template v-if="location.main_image_link">
                            <q-carousel height="90px" class="product-main-image" v-model="slide">
                                <q-carousel-slide :name="1" class="p-0">
                                    <img :src="baseUrl + location.main_image_link" class="slide-main-image" />
                                </q-carousel-slide>
                            </q-carousel>
                        </template>
                        <template v-else>
                            <q-carousel height="90px" class="product-main-image no-image" v-model="slide">
                                <q-carousel-slide :name="1">
                                    <div class="absolute-center custom-caption text-center">
                                        <q-icon name="image" />
                                    </div>
                                </q-carousel-slide>
                            </q-carousel>
                        </template>
                        <q-card-section class="font-weight-light small pt-2 pb-2 pl-1 pr-1 line-height-1 text-center">
                            <strong>{{ location.name }}</strong>
                        </q-card-section>
                    </q-card>
                </div>
                <b-card v-if="service_locations && service_locations.length === 0" no-header class="full-width p-0 mt-2 text-muted">
                    {{ translate(no_service_locations_found_message) }}
                </b-card>
            </div>
            <infinite-loading slot="append" :identifier="'appointments-infServiceLocations-' + infServiceLocations" @infinite="getServiceLocations" />
        </div>
    </div>
    <div :class="showCalendar" class="mb-2 mb-md-4 bg-white">
        <div class="fit" bg-color="primary">
            <template>
                <q-list v-if="viewOptions" v-model="tab" class="fit row m-0 justify-center">
                    <q-item :name="'tab' + index" clickable class="col-xs-6 col-sm-6 col-md-3 p-0 justify-center items-center view-action-li text-indigo-10" :class="{active: calendarView == view.value}" v-for="(view, index) in viewOptions" :text="view.value" :key="view.value">
                        <q-btn color="black" class="full-width m-0 full-height bg-blue-grey-2" flat @click="setView(view)">{{ view.label }}</q-btn>
                        <div class="q-tab__indicator absolute-bottom"></div>
                    </q-item>
                </q-list>
            </template>
            <q-item class="bg-primary full-width">
                <q-item-section side>
                    <q-btn flat dense round text-color="white" icon="keyboard_arrow_left" @click="onPrev"></q-btn>
                </q-item-section>
                <q-item-section>
                    <span class="ml-2 nowrap q-toolbar-title">{{ title }}</span>
                </q-item-section>
                <q-item-section side>
                    <q-btn flat dense round text-color="white" icon="my_location" @click="setToday" />
                </q-item-section>
                <!-- <q-item-section side>
                    <q-btn flat dense round @click="$q.dark.toggle()" :icon="$q.dark.isActive ? 'brightness_2' : 'brightness_5'"/>
                </q-item-section> -->
                <q-item-section side>
                    <q-btn flat dense round text-color="white" icon="keyboard_arrow_right" @click="onNext"></q-btn>
                </q-item-section>
            </q-item>
        </div>

        <div class="calendar-container appointment full-width" :style="containerStyle">
            <transition-group appear enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
                <q-calendar 
                    :weekday-skips="weekdaySkips" :weekdays="weekdays" @input="setCalendarIntervalDates" ref="calendar" v-model="selectedDate" :view="calendarView" locale="en-us" style="width: 100%; overflow-x: hidden;" :class="'calendar ' + calendarView" dayPadding="35px 2px" :key="keyValue" :interval-minutes="intervalMinutes" 
                    :interval-start="interval_start_hour * (fullHourMinutes / intervalMinutes)" 
                    :interval-height="interval_height" 
                    :interval-count="(interval_end_hour * (fullHourMinutes / intervalMinutes)) - (interval_start_hour * (fullHourMinutes / intervalMinutes))" 
                    :hour24-format="true" :interval-style="modifiedStyle" :day-style="modifiedStyle" :resource-style="modifiedStyle" :enable-theme="false" 
                    @click:date="onDateChanged" @click:time="showAddAppointmentPanel($event)">
                    <template #day="{ date }">
                        <template v-if="calendarView.indexOf('agenda') < 0">
                            <template v-for="(event, index) in getEvents(date)">
                                <q-badge :key="index" style="border-radius: 0; justify-content: center; width: 100%; cursor: pointer; height: 14px; max-height: 14px" :class="badgeClasses(event, 'day')" :style="badgeStyles(event, 'day')" @click.stop.prevent="showEditAppointmentPanel(event)" :draggable="true" @dragstart="(e) => onDragStart(e, event)" @dragend="(e) => onDragEnd(e, event)" @dragenter="(e) => onDragEnter(e, event)" @touchmove="(e) => {}">
                                    <q-icon v-if="event.icon" :name="event.icon" class="q-mr-xs"></q-icon><span>{{ event.customer_name }}</span>
                                </q-badge>
                            </template>
                        </template>
                    </template>

                    <template #day-header="{ date }">
                        <div v-if="calendarView.indexOf('agenda') < 0" class="row justify-center">
                            <template v-for="(event, index) in eventsMap[date]">
                                <q-badge v-if="!event.time" :key="index" style="width: 100%; cursor: pointer; height: 14px; max-height: 14px" :class="badgeClasses(event, 'header')" :style="badgeStyles(event, 'header')" @click.stop.prevent="showEditAppointmentPanel(event)" :draggable="true" @dragstart="(e) => onDragStart(e, event)" @dragend="(e) => onDragEnd(e, event)" @dragenter="(e) => onDragEnter(e, event)" @touchmove="(e) => {}">
                                    <q-icon v-if="event.icon" :name="event.icon" class="q-mr-xs"></q-icon><span>{{ event.customer_name }}</span>
                                </q-badge>
                            </template>
                        </div>
                    </template>

                    <template #day-body="data">
                        <template v-if="calendarView.indexOf('agenda') < 0">
                            <template v-for="(event, index) in getEvents(data.date)">
                                <b-card v-if="event.time" stretch no-body class="my-event p-1" :key="index" :class="badgeClasses(event, 'body')" :style="badgeStyles(event, 'body', data.timeStartPos, data.timeDurationHeight)" @click.stop.prevent="showEditAppointmentPanel(event)" :draggable="true" @dragstart="(e) => onDragStart(e, event)" @dragend="(e) => onDragEnd(e, event)" @dragenter="(e) => onDragEnter(e, event)" @touchmove="(e) => {}">
                                    <p class="text-center">
                                        <strong>{{event.customer_name}}</strong>
                                        <br />
                                        <em class="text-grey-3">{{event.worker_name}}</em>
                                    </p>
                                </b-card>
                            </template>
                        </template>
                    </template>

                    <template #intervals-header="days">
                        <div class="fit flex justify-center items-end">
                            <span class="q-calendar-daily__interval-text">{{ showOffset(days) }}</span>
                        </div>
                    </template>

                    <!-- eslint-disable vue/no-unused-vars -->
                    <template #day-container="{ date }">
                        <div class="day-view-current-time-indicator" :class="date" :style="style" />
                        <div class="day-view-current-time-line" :class="date" :style="style" />
                    </template>
                    <template #interval="{ time }">
                        <div class="q-calendar-daily__interval-text interval-start-hour-minute">{{ showHoursIntervalStart(time) }}</div>
                    </template>
                </q-calendar>
            </transition-group>
        </div>
    </div>
</div>
</template>

<style src="@quasar/quasar-ui-qcalendar/dist/index.css"></style>
<style src="@quasar/quasar-ui-qiconpicker/dist/index.css"></style>

<script>
import _ from 'lodash'
import { eventBus } from '../../../main'
import AddAppointment from './forms/AddAppointment'
import EditAppointment from './forms/EditAppointment'

import {
    QCalendar
} from '@quasar/quasar-ui-qcalendar'
import {
    Component as QIconPicker
} from '@quasar/quasar-ui-qiconpicker'

import {
    Component as QScroller
} from '@quasar/quasar-ui-qscroller'

import {
    isCssColor
} from './utils/color'
import {
    padTime
} from './utils/time'
import events from './utils/events'
import {
    date,
    colors,
    Platform,
    QScrollArea
} from 'quasar'
import {
    parseDate
} from '@quasar/quasar-ui-qcalendar'
import {
    parsed,
    getDayIdentifier,
    getTimeIdentifier
} from '@quasar/quasar-ui-qcalendar/src/utils/timestamp'
import {
    getLocale
} from './utils/getLocale'
import {
    stop,
    prevent,
    stopAndPrevent
} from 'quasar/src/utils/event'
import ChooseCustomer from '../schedules/forms/ChooseCustomerWorker'

const formDefault = {
    title: '',
    details: '',
    allDay: false,
    dateTimeStart: '',
    dateTimeEnd: '',
    icon: '',
    bgcolor: '#0000FF'
}

import AddService from '../../offers/services/forms/AddService'
import AddLocation from '../../business/locations/forms/AddLocation'

export default {
    name: 'AppointmentsCalendarWorker',
    components: {
        QCalendar,
        QIconPicker,
        QScroller,
        QScrollArea,
        'choose-customer': ChooseCustomer,
    },
    mounted() {
        this.$root.$on('calendar:next', this.onNext)
        this.$root.$on('calendar:prev', this.onPrev)
        this.$root.$on('calendar:today', this.setToday)
        eventBus.$on('update_services', () => {
            this.resetServicesList()
        })
        eventBus.$on('update_locations', () => {
            this.resetServiceLocationsList()
        })
        this.updateFormatters()
        this.adjustCurrentTime()
        // now, adjust the time every minute
        this.intervalId = setInterval(() => {
            this.adjustCurrentTime()
        }, 60000)
        eventBus.$on('delete_appointment', (id) => {
            this.deleteAppointment(id)
        })
    },
    beforeDestroy() {
        clearInterval(this.intervalId)
    },
    computed: {
        inactive_appointment_title: function () {
            return this.translate('inactive_appointment_title')
        },
        style() {
            return {
                top: this.timeStartPos + 'px'
            }
        },
        title: {
            get() {
                if (this.titleFormatter && this.locale && this.selectedDate) {
                    const date = new Date(this.selectedDate)
                    return this.titleFormatter.format(date)
                }
                return this.getCalendarTitle()
            },
            set() {
                if (this.titleFormatter && this.locale && this.selectedDate) {
                    const date = new Date(this.selectedDate)
                    return this.titleFormatter.format(date)
                }
                return this.getCalendarTitle()
            }
        },
        intervalStart() {
            return this.intervalRange.min * (1 / this.intervalRangeStep)
        },
        intervalCount() {
            return (this.intervalRange.max - this.intervalRange.min) * (1 / this.intervalRangeStep)
        },
        containerStyle() {
            const styles = {}
            if (this.calendarView !== 'month') {
                styles.height = `calc(100vh - ${this.titlebarHeight}px)`
            }
            return styles
        },
        // convert the events into a map of lists keyed by date
        eventsMap() {
            const map = {}
            this.events.forEach((event) => {
                (map[event.date] = map[event.date] || []).push(event)
            })
            return map
        },
        addOrUpdateEvent() {
            if (this.contextDay && this.contextDay.bgcolor) {
                return 'Update'
            }
            return 'Add'
        },
        scrollerPopupStyle160() {
            if (this.$q.screen.lt.sm) {
                return {
                    width: '100vw',
                    height: '100vh'
                }
            } else {
                return {
                    maxHeight: '400px',
                    height: '400px',
                    width: '160px'
                }
            }
        },
        scrollerPopupStyle280() {
            if (this.$q.screen.lt.sm) {
                return {
                    width: '100vw',
                    height: '100vh'
                }
            } else {
                return {
                    maxHeight: '400px',
                    height: '400px',
                    width: '280px'
                }
            }
        }
    },
    data: function () {
        return {
            isTypingAppointmentService: false,
            isTypingAppointmentServiceLocation: false,
            searchAppointmentService: '',
            searchAppointmentServiceLocation: '',
            no_services_found_message: 'no_services_found',
            no_service_locations_found_message: 'no_service_locations_found',
            
            interval_height: '30px',
            show_cards: true,
            infServiceLocations: +new Date(),
            infServices: +new Date(),
            customer_id: 0,
            customer_name: '',

            interval_start_hour: 0,
            interval_end_hour: 24,
            
            weekdays: [],
            weekdaySkips: [],
            showAllLocations: false,
            selected_service_card: 0,
            selected_location_card: 0,
            baseUrl: baseUrl,
            showCalendar: 'hidden-calendar',

            slide: 1,
            events: [],
            currentDate: void 0,
            currentTime: void 0,
            intervalId: null,
            timeStartPos: 0,

            tab: 'tab2',
            intervalMinutes: 30,
            fullHourMinutes: 60,
            currentView: 0,
            keyValue: 0,
            direction: 'forward',
            titleFormatter: null,
            contentClass: 'mat',
            calendarView: 'week',
            viewOptions: viewOptionsList(),

            addEvent: false,
            contextDay: null,
            eventForm: {
                ...formDefault
            },
            displayEvent: false,
            event: null,
            events: events,
            gmt: '',
            dragging: false,
            draggedEvent: null,
            ignoreNextSwipe: false,
            showDateScrollerAllDay: false,
            showDateTimeScrollerStart: false,
            showDateTimeScrollerEnd: false,

            selectedDate: '',
            selected_location: null,
            selected_service: {
                locations: []
            },
            service_locations: [],
            services: [],
            appointments: [],
            message: '',
            order_by: '',
            page: 1,
            page_service: 1,
            page_service_locations: 1,
            enableCard: false,

            appointments_start_date: '',
            appointments_end_date: '',
            state: {}
        }
    },
    created() {
        this.setCalendarWeekdaysOrder()
        this.setCalendarWeekdaySkips()
        this.setCalendarIntervalDates()
    },
    beforeMount() {
        this.locale = getLocale()
        this.updateFormatters()
    },
    watch: {
        searchAppointmentService: _.debounce(function () {
            this.isTypingAppointmentService = false
        }, 200),
        searchAppointmentServiceLocation: _.debounce(function () {
            this.isTypingAppointmentServiceLocation = false
        }, 200),
        isTypingAppointmentService: function (value) {
            if (!value) {
                this.resetServicesList()
                if (!this.services || this.services.length === 0) {
                    this.no_services_found_message = 'no_services_found_on_search'
                }
            }
        },
        isTypingAppointmentServiceLocation: function (value) {
            if (!value) {
                this.resetServiceLocationsList()
                if (!this.service_locations || this.service_locations.length === 0) {
                    this.no_service_locations_found_message = 'no_service_locations_found_on_search'
                }
            }
        },
        locale() {
            if (this.locale.length > 2) {
                this.updateFormatters()
            }
        },
        shortMonthLabel() {
            this.updateFormatters()
        },
    },
    methods: {
        showAddServicePanel: function () {
            const panelInstance = this.$showPanel({
                component: AddService,
                props: {
                    selected_service_type: 's_appointment'
                }
            })

            panelInstance.promise.then(result => {
                if (result && result.status) {
                    eventBus.$emit('update_services')
                }
            })
        },
        showAddLocationPanel: function () {
            const panelInstance = this.$showPanel({
                component: AddLocation,
            })

            panelInstance.promise.then(result => {
                if (result && result.status) {
                    eventBus.$emit('update_locations')
                }
            })
        },
        deleteAppointment: function (id) {
            // Find index of specific object using findIndex method,
            var objIndex = this.events.findIndex((obj => obj.id === id))
            // and remove the element from the events array.
            this.events.splice(objIndex, 1)
        },
        showCards: function () {
            this.show_cards = true
        },
        hideCards: function () {
            this.show_cards = false
        },
        updateCustomer: function (customer) {
            if (customer) {
                this.customer_id = customer.id
                this.customer_name = customer.name
                this.showCards()
                // Get the appointments for the selected location.
                this.getAppointmentsForSelectedLocation()
                // this.resetServicesList()
            }
        },
        resetServiceLocationsList: function () {
            this.page_service_locations = 1
            this.service_locations = []
            this.infServiceLocations++
        },
        resetServicesList: function () {
            this.page_service = 1
            this.services = []
            this.infServices++
        },
        setCalendarWeekdaySkips: function () {
            var skips = []
            var to_skip = 0
            this.weekdays.forEach(function (index) {
                skips.push(0)
                if (index === to_skip) {
                    skips.push(1)
                }
            })
            // this.weekdaySkips = skips
            return
        },
        setCalendarWeekdaysOrder: function () {
            this.weekdays = [1, 2, 3, 4, 5, 6, 0]
        },
        setCalendarIntervalDates: _.debounce(function (start, end) {
            // Set the start/end dates to retrieve the locations for service.
            var refs = this.$refs
            if (refs.calendar) {
                this.appointments_start_date = refs.calendar.lastStart
                this.appointments_end_date = refs.calendar.lastEnd

                // Get the appointments for the selected location.
                this.getAppointmentsForSelectedLocation()
            }
        }, 300),
        setSelectedService(service) {
            this.displayTheCalendar(false)
            
            if (service && service.id) {
                this.selected_service_card = service.id
                this.selected_location_card = 0
    
                // Set selected_service.
                this.selected_service = JSON.parse(JSON.stringify(service))
    
                // Get planning locations for the selected service.
                this.setCalendarIntervalDates()
    
                // Show the locations.
                this.showAllLocations = true
                this.resetServiceLocationsList()
            }
            
            return
        },
        setSelectedLocation(location) {
            if (location && location.id) {
                this.selected_location_card = location.id
                // Set selected location.
                this.selected_location = location
                
                // Set location start_time/end_time.
                this.setLocationWorkingHours(location)
    
                // Get the appointments for the selected location.
                this.getAppointmentsForSelectedLocation()
            }

            return
        },
        setLocationWorkingHours: function (location) {
            if (location) {
                this.interval_start_hour = parseInt(location.start_time.slice(0, 2)) === 0 ? 0 : location.start_time.slice(0, 2)
                this.interval_end_hour = parseInt(location.end_time.slice(0, 5)) === 0 ? 24 : 
                    (parseInt(location.end_time.slice(3, 5)) > 0 ? parseInt(location.end_time.slice(0, 2)) + 1 : location.end_time.slice(0, 2))
            }

            return
        },
        displayTheCalendar: function (display) {
            if (display === true) {
                this.hideCards()
                this.showCalendar = ''
            } else {
                this.showCards()
                this.showCalendar = 'hidden-calendar'
            }
        },
        adjustCurrentTime: _.debounce(function () {
            if (this.$refs.calendar) {
                const now = new Date()
                const p = parseDate(now)
                this.currentDate = p.date
                this.currentTime = p.time
                this.timeStartPos = this.$refs.calendar.timeStartPos(this.currentTime)
            }
        }, 100),
        setView(view) {
            this.calendarView = view.value
            // Get appointment locations for the selected service.
            this.setCalendarIntervalDates()
        },
        isCssColor(color) {
            return !!color && !!color.match(/^(#|(rgb|hsl)a?\()/)
        },
        getCalendarTitle(given_date) {
            let date = new Date()
            let title = ''
            if (given_date) {
                date = new Date(given_date)
            }
            date.toJSON().slice(0, 10)
            title = date.toLocaleString('default', {
                month: 'long'
            }) + ' ' + date.getFullYear()

            return title
        },
        adjustTimestamp(day) {
            day.minute = day.minute < 15 || day.minute >= 45 ? 0 : 30
            return day
        },
        getTimestamp(day) {
            return day.date + ' ' + padTime(day.hour) + ':' + padTime(day.minute) + ':00.000'
        },
        saveEvent() {
            const self = this
            this.$refs.event.validate().then((success) => {
                if (success) {
                    // close the dialog
                    self.addEvent = false
                    const form = {
                        ...self.eventForm
                    }
                    let update = false
                    if (self.contextDay.bgcolor) {
                        // an update
                        update = true
                    } else {
                        // an add
                    }
                    const data = {
                        title: form.title,
                        details: form.details,
                        icon: form.icon,
                        bgcolor: form.bgcolor,
                        date: String(form.dateTimeStart).slice(0, 10).replace(/\//g, '-')
                    }
                    if (form.allDay === false) {
                        // get time into separate var
                        data.time = String(form.dateTimeStart).slice(11, 16)
                        data.duration = self.getDuration(form.dateTimeStart, form.dateTimeEnd, 'minutes')
                    }
                    if (update === true) {
                        const index = self.findEventIndex(self.contextDay)
                        if (index >= 0) {
                            self.events.splice(index, 1, {
                                ...data
                            })
                        }
                    } else {
                        // add to events array
                        self.events.push(data)
                    }
                    self.contextDay = null
                }
            })
        },
        showOffset(days) {
            if (days.length === 0) return
            const val = padTime(new Date(this.getTimestamp(days[0])).getTimezoneOffset() / 60)
            if (isNaN(val)) return ''
            return 'GMT-' + val
        },
        showHoursIntervalStart(time) {
            if (this.$refs.calendar && this.$refs.calendar.intervalStart) {
                if (this.$refs.calendar.intervalStart / 2 < 10) {
                    if ('0' + this.$refs.calendar.intervalStart / 2 + ':00' === time) {
                        return time
                    }
                }
                if (this.$refs.calendar.intervalStart / 2 + ':00' === time) {
                    return time
                }
            }
            return ''
        },
        badgeClasses(event, type) {
            const cssColor = isCssColor(event.bgcolor)
            const isHeader = type === 'header'
            return {
                [`text-white bg-${event.bgcolor}`]: !cssColor,
                'full-width': !isHeader && (!event.side || event.side === 'full'),
                'left-side': !isHeader && event.side === 'left',
                'right-side': !isHeader && event.side === 'right'
            }
        },
        badgeStyles(event, type, timeStartPos, timeDurationHeight) {
            const s = {}
            if (isCssColor(event.bgcolor)) {
                s['background-color'] = event.bgcolor
                s.color = colors.luminosity(event.bgcolor) > 0.5 ? 'black' : 'white'
            }
            if (timeStartPos) {
                s.top = timeStartPos(event.time) + 'px'
            }
            if (timeDurationHeight) {
                s.height = timeDurationHeight(event.duration) + 'px'
            }
            return s
        },
        displayClasses(event) {
            return {
                [`bg-${event.bgcolor}`]: !isCssColor(event.bgcolor),
                'text-white': !isCssColor(event.bgcolor)
            }
        },
        displayStyles(event) {
            const s = {}
            if (isCssColor(event.bgcolor)) {
                s['background-color'] = event.bgcolor
                s.color = colors.luminosity(event.bgcolor) > 0.5 ? 'black' : 'white'
            }
            return s
        },
        resetForm() {
            this.$set(this, 'eventForm', {
                ...formDefault
            })
        },
        showEvent(event) {
            if (this.calendarView.indexOf('agenda') < 0) {
                this.event = event
                this.displayEvent = true
            }
        },
        getEndTime(event) {
            let endTime = new Date(event.date + ' ' + event.time + ':00')
            endTime = date.addToDate(endTime, {
                minutes: event.duration
            })
            endTime = date.formatDate(endTime, 'HH:mm')
            return endTime
        },
        getEventDate(event) {
            const parts = event.date.split('-')
            const date = new Date(parts[0], parts[1] - 1, parts[2])
            return this.dateFormatter.format(date)
        },
        editEvent(event) {
            this.resetForm()
            this.contextDay = {
                ...event
            }
            let timestamp
            if (event.time) {
                timestamp = event.date + ' ' + event.time
                const startTime = new Date(timestamp)
                const endTime = date.addToDate(startTime, {
                    minutes: event.duration
                })
                this.eventForm.dateTimeStart = this.formatDate(startTime) + ' ' + this.formatTime(startTime) // endTime.toString()
                this.eventForm.dateTimeEnd = this.formatDate(endTime) + ' ' + this.formatTime(endTime) // endTime.toString()
            } else {
                timestamp = event.date
                this.eventForm.dateTimeStart = timestamp
            }
            this.eventForm.allDay = !event.time
            this.eventForm.bgcolor = event.bgcolor
            this.eventForm.icon = event.icon
            this.eventForm.title = event.title
            this.eventForm.details = event.details
            this.addEvent = true // show dialog
        },
        findEventIndex(event) {
            for (let i = 0; i < this.events.length; ++i) {
                if (event.title === this.events[i].title &&
                    event.details === this.events[i].details &&
                    event.date === this.events[i].date) {
                    return i
                }
            }
        },
        formatDate(date) {
            const d = date !== void 0 ? new Date(date) : new Date(),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear()
            return [year, padTime(month), padTime(day)].join('-')
        },
        formatTime(date) {
            const d = date !== void 0 ? new Date(date) : new Date(),
                hours = '' + d.getHours(),
                minutes = '' + d.getMinutes()
            return [padTime(hours), padTime(minutes)].join(':')
        },
        getDuration(dateTimeStart, dateTimeEnd, unit) {
            const start = new Date(dateTimeStart)
            const end = new Date(dateTimeEnd)
            const diff = date.getDateDiff(end, start, unit)
            return diff
        },
        convertDurationTime(n) {
            const num = n
            const days = Math.floor(((num / 60) / 24))
            const hours = (num / 60)
            const rhours = Math.floor(hours)
            const rshours = Math.floor(hours - (days * 24))
            const minutes = (hours - rhours) * 60
            const rminutes = Math.round(minutes)
            return (days > 0 ? days + ' days and ' : '') + (rshours > 0 ? rshours + ' hour(s) and ' : '') + rminutes + ' minute(s).'
        },
        onSubmit() {
            this.saveEvent()
        },
        onReset() {},
        getEvents(dt) {
            const events = []
            for (let i = 0; i < this.events.length; ++i) {
                let added = false
                if (this.events[i].date === dt) {
                    if (this.events[i].time) {
                        if (events.length > 0) {
                            // check for overlapping times
                            const startTime = new Date(this.events[i].date + ' ' + this.events[i].time).getTime()
                            const endTime = date.addToDate(startTime, {
                                minutes: this.events[i].duration
                            }).getTime()
                            for (let j = 0; j < events.length; ++j) {
                                const startTime2 = new Date(events[j].date + ' ' + events[j].time).getTime()
                                const endTime2 = date.addToDate(startTime2, {
                                    minutes: events[j].duration
                                }).getTime()
                                if ((startTime >= startTime2 && startTime < endTime2) || (startTime2 >= startTime && startTime2 < endTime)) {
                                    events[j].side = 'left'
                                    this.events[i].side = 'right'
                                    events.push(this.events[i])
                                    added = true
                                    break
                                }
                            }
                        }
                    }
                    if (!added) {
                        this.events[i].side = void 0
                        events.push(this.events[i])
                    }
                } else if (this.events[i].days) {
                    // check for overlapping dates
                    const startDate = new Date(this.events[i].date)
                    const endDate = date.addToDate(startDate, {
                        days: this.events[i].days
                    })
                    if (date.isBetweenDates(dt, startDate, endDate)) {
                        events.push(this.events[i])
                        added = true
                    }
                }
            }
            return events
        },
        checkDateTimeStart( /* val */ ) {
            this.$refs.dateTimeEnd.resetValidation()
            if (this.eventForm.dateTimeStart && this.eventForm.dateTimeEnd) {
                const timestampStart = parsed(this.eventForm.dateTimeStart)
                const timestampEnd = parsed(this.eventForm.dateTimeEnd)
                const dayStart = getDayIdentifier(timestampStart)
                const dayEnd = getDayIdentifier(timestampEnd)
                if (dayStart < dayEnd) {
                    return true
                } else if (dayStart > dayEnd) {
                    return false
                } else {
                    const timeStart = getTimeIdentifier(timestampStart)
                    const timeEnd = getTimeIdentifier(timestampEnd)
                    if (timeStart <= timeEnd) {
                        return true
                    } else {
                        return false
                    }
                }
            }
            return false
        },
        checkDateTimeEnd(val) {
            this.$refs.dateTimeStart.resetValidation()
            if (this.eventForm.dateTimeStart && this.eventForm.dateTimeEnd) {
                const timestampEnd = parsed(this.eventForm.dateTimeEnd)
                const timestampStart = parsed(this.eventForm.dateTimeStart)
                const dayEnd = getDayIdentifier(timestampEnd)
                const dayStart = getDayIdentifier(timestampStart)
                if (dayEnd > dayStart) {
                    return true
                } else if (dayEnd < dayStart) {
                    return false
                } else {
                    const timeEnd = getTimeIdentifier(timestampEnd)
                    const timeStart = getTimeIdentifier(timestampStart)
                    if (timeEnd >= timeStart) {
                        return true
                    } else {
                        return false
                    }
                }
            }
            return false
        },
        onDateChanged({
            day
        }) {
            // automatically change to the day selected
            this.calendarView = 'day'
            this.adjustCurrentTime()
        },
        addEventMenu(day, type) {
            if (day.disabled === true || this.calendarView.indexOf('scheduler') > -1 || this.calendarView.indexOf('agenda') > -1) {
                return
            }
            this.resetForm()
            this.contextDay = {
                ...day
            }
            let timestamp
            if (this.contextDay.hasTime === true) {
                timestamp = this.getTimestamp(this.adjustTimestamp(this.contextDay))
                const startTime = new Date(timestamp)
                const endTime = date.addToDate(startTime, {
                    hours: 1
                })
                this.eventForm.dateTimeEnd = this.formatDate(endTime) + ' ' + this.formatTime(endTime) // endTime.toString()
            } else {
                timestamp = this.contextDay.date + ' 00:00'
            }
            this.eventForm.dateTimeStart = timestamp
            this.eventForm.allDay = this.contextDay.hasTime === false
            this.eventForm.bgcolor = '#0000FF' // starting color
            this.addEvent = true // show dialog
        },
        updateFormatters() {
            try {
                this.dateFormatter = new Intl.DateTimeFormat(this.locale || void 0, {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                    hour12: false,
                    timeZone: 'UTC'
                })
                this.titleFormatter = new Intl.DateTimeFormat(this.locale || void 0, {
                    month: this.shortMonthLabel ? 'short' : 'long',
                    year: 'numeric',
                    timeZone: 'UTC'
                })
            } catch (e) {
                // console.error('Intl.DateTimeFormat not supported')
                this.dateFormatter = void 0
                this.titleFormatter = void 0
            }
        },
        modifiedStyle(scope) {
            let date = scope
            if ('resource' in scope) {
                date = scope.day
            }
            if (date.disabled === true) {
                return {
                    backgroundColor: '#efefef!important'
                }
            }
            return {}
        },
        onNext() {
            this.$refs.calendar.next()
            this.title = this.getCalendarTitle(this.$refs.calendar.start)
        },
        onPrev() {
            this.$refs.calendar.prev()
            this.title = this.getCalendarTitle(this.$refs.calendar.start)
        },
        setToday() {
            this.selectedDate = this.getCurrentDate()
            // Get appointment locations for the selected service.
            this.setCalendarIntervalDates()
        },
        handleSwipe({
            evt,
            ...info
        }) {
            if (this.dragging === false) {
                if (info.duration >= 30 && this.ignoreNextSwipe === false) {
                    if (info.direction === 'right') {
                        this.onPrev()
                    } else if (info.direction === 'left') {
                        this.onNext()
                    }
                } else {
                    this.ignoreNextSwipe = false
                }
            }
            stopAndPrevent(evt)
        },
        onDragEnter(ev, event) {
            prevent(ev)
        },
        onDragStart(ev, event) {
            this.dragging = true
            this.draggedEvent = event
            stop(ev)
        },
        onDragEnd(ev, event) {
            stopAndPrevent(ev)
            this.resetDrag()
        },
        onDragOver(ev, day, type) {
            if (type === 'day') {
                stopAndPrevent(ev)
                return this.draggedEvent.date !== day.date
            } else if (type === 'interval') {
                stopAndPrevent(ev)
                return this.draggedEvent.date !== day.date && this.draggedEvent.time !== day.time
            }
        },
        onDrop(ev, day, type) {
            ev.preventDefault()
            ev.stopPropagation()
            if (type === 'day') {
                this.draggedEvent.date = day.date
                this.draggedEvent.side = void 0
            } else if (type === 'interval') {
                this.draggedEvent.date = day.date
                this.draggedEvent.time = day.time
                this.draggedEvent.side = void 0
            }
        },
        resetDrag() {
            this.draggedEvent = void 0
            this.dragging = false
            if (Platform.is.desktop) {
                this.ignoreNextSwipe = true
            }
        },
        getCurrentDate() {
            var currentDateWithFormat = new Date().toJSON().slice(0, 10);
            return currentDateWithFormat
        },
        getAppointmentsForSelectedLocation: function () {
            var selected_location = this.selected_location
            var selected_service = this.selected_service

            if (selected_location) {
                var service_id = selected_service.id
                var location_id = selected_location.id
                var start_date = this.appointments_start_date
                var end_date = this.appointments_end_date
                var url = baseUrl + 'appointments?service_id=' + service_id +
                    '&location_id=' + location_id +
                    '&start_date=' + start_date +
                    '&end_date=' + end_date
                var accessToken = this.accessToken()
                var headers = {
                    "Authorization": "Bearer " + accessToken,
                    "Content-Type": "application/json"
                }
                var mapped_appointments = []

                axios.get(url, {
                        headers: headers
                    })
                    .then(response => {
                        var appointments = response.data.items
                        for (let [key, appointment] of Object.entries(appointments)) {
                            appointment.forEach(function (appointment) {
                                var start_time = appointment.start_time.replace(/(:\d{2}| [AP]M)$/, ""),
                                    end_time = appointment.end_time.replace(/(:\d{2}| [AP]M)$/, ""),
                                    date_key_start = new Date(key + ' ' + appointment.start_time),
                                    date_key_end = new Date(key + ' ' + appointment.end_time),
                                    duration_timestamp = date_key_end.getTime() / 1000 - date_key_start.getTime() / 1000,
                                    duration_minutes = duration_timestamp / 60

                                mapped_appointments.push({
                                    appointment_id: appointment.id,
                                    title: selected_service.name,
                                    details: selected_location.name,
                                    date: key,
                                    time: start_time,
                                    start_time: start_time,
                                    end_time: end_time,
                                    location: selected_location.name,
                                    duration: duration_minutes,
                                    bgcolor: 'blue',
                                    customer_name: appointment.customer_name,
                                    worker_name: appointment.worker_name,
                                })
                            })
                        }

                        if (this.selected_location_card) {
                            // Show the calendar.
                            this.displayTheCalendar(true)
                        }
                        this.events = mapped_appointments
                        this.adjustCurrentTime()
                    })
                    .catch(function (error) {
                        // console.log(error)
                    })
            }
        },
        getServiceLocations: function ($state) {
            var locationUrl = baseUrl +
                'plannings/locations?page=' + this.page_service_locations +
                '&items_per_page=' + this.$items_per_page + 
                (this.searchAppointmentServiceLocation ? '&search=' + this.searchAppointmentServiceLocation : '')
            var accessToken = this.accessToken()
            var headers = {
                "Authorization": "Bearer " + accessToken,
                "Content-Type": "application/json"
            }
            var mapped_locations = []
            this.state = $state
            // To solve latency with server, push the items into a temporary scoped array and then into the main array.
            let service_locations = this.service_locations
            return axios.get(locationUrl, {
                    headers: headers
                })
                .then(response => {
                    this.adjustCurrentTime()
                    $state = this.state

                    if (response.data.items && response.data.items.length > 0) {
                        service_locations.push(...response.data.items)
                    }
                    if (response.data.next_page === true) {
                        this.page_service_locations++
                        $state.loaded()
                    } else {
                        // To solve latency with server, push the items into a temporary scoped array and then into the main array.
                        this.service_locations = service_locations
                        $state.complete()

                        // Set selected service location: if only 1 item in list.
                        if (response.data.items[0] && response.data.items.length === 1) {
                            this.setSelectedLocation(response.data.items[0])
                        } else {
                            this.setSelectedLocation(null)
                        }
                    }
                })
                .catch(function (error) {
                    // console.log(error)
                })
        },
        getServices: function ($state) {
            var serviceUrl = baseUrl +
                'appointments/services?page=' + this.page_service +
                '&items_per_page=' + this.$items_per_page + 
                (this.searchAppointmentService ? '&search=' + this.searchAppointmentService : '')

            var headers = {
                'Authorization': 'Bearer ' + this.accessToken(),
            }
            this.state = $state
            // To solve latency with server, push the items into a temporary scoped array and then into the main array.
            let services = this.services
            axios.get(serviceUrl, {
                    headers: headers
                })
                .then(response => {
                    $state = this.state

                    if (response.data.items && response.data.items.length > 0) {
                        services.push(...response.data.items)
                    }
                    if (response.data.next_page === true) {
                        this.page_service++
                        $state.loaded()
                    } else {
                        // To solve latency with server, push the items into a temporary scoped array and then into the main array.
                        this.services = services
                        $state.complete()

                        // Set selected service: if only 1 item in list.
                        if (response.data.items[0] && response.data.items.length === 1) {
                            this.showCalendar = ''
                            this.setSelectedService(response.data.items[0])
                        } else {
                            this.setSelectedService(null)
                        }
                    }
                })
                .catch(function (error) {
                    // console.log(error);
                });
        },
        showAddAppointmentPanel(event) {
            event = event.scope ? event.scope : event
            
            var props = {
                service: this.selected_service,
                location: this.selected_location,
                event: event,
                customer_name: this.customer_name,
                customer_id: this.customer_id
            }
            const panelInstance = this.$showPanel({
                component: AddAppointment,
                props: props
            });

            panelInstance.promise.then(result => {
                if (result && result.status) {
                    this.getAppointmentsForSelectedLocation()
                }
            });
        },
        showEditAppointmentPanel(event) {
            var props = {
                service: this.selected_service,
                location: this.selected_location,
                event: event
            }
            const panelInstance = this.$showPanel({
                component: EditAppointment,
                props: props
            })
            panelInstance.promise.then(result => {
                if (result && result.status) {
                    this.getAppointmentsForSelectedLocation()
                }
            });
        },
    },
}

// Calendar View options list.
function viewOptionsList() {
    return [{
            label: 'Day',
            value: 'day'
        },
        {
            label: '3 Days',
            value: '3day'
        },
        {
            label: 'Week',
            value: 'week'
        },
        {
            label: 'Month',
            value: 'month'
        }
    ]
}
</script>

<style scoped lang="sass">
    // calendar overrides
    .q-calendar-daily__day-interval:hover
        background-color: rgba(0,0,255,.1)
    .q-calendar-weekly__workweek:hover
        background-color: rgba(0,0,255,.1)
    .q-calendar-weekly__day:hover
        background-color: rgba(0,0,255,.1)
    .q-calendar-weekly__head-weekday:hover
        background-color: rgba(0,0,255,.1)
    
    .q-calendar-weekly__day.q-future-day[style*="background-color: rgb(239, 239, 239) !important;"]
        pointer-events: none
        cursor: not-allowed

    // this page
    .calendar-container
        position: relative
        width: 100%
        max-width: 100%
        overflow-x: hidden

    .my-event
        width: 100%
        position: absolute
        font-size: 12px
        border-radius: 0
        cursor: pointer
        justify-content: flex-start
        align-items: center!important
        box-shadow: none
        border: none

    .full-width
        left: 0
        width: 100%

    .left-side
        left: 0
        width: 49.75%

    .right-side
        left: 50.25%
        width: 49.75%

    .day-view-current-time-indicator
        position: absolute
        left: 45px
        height: 10px
        width: 10px
        margin-top: -4px
        background-color: rgba(0, 0, 255, .5)
        border-radius: 50%

    .day-view-current-time-line
        position: absolute
        left: 55px
        border-top: rgba(0, 0, 255, .5) 2px solid
        width: calc(100% - 50px - 5px)

    .body--dark
        .day-view-current-time-indicator
            background-color: rgba(255, 255, 0, .85)

        .day-view-current-time-line
            border-top: rgba(255, 255, 0, .85) 2px solid

    .horizontal-list
        width: auto
        overflow-x: auto
        flex-direction: row
        flex-flow: nowrap
        .list-item
            .q-card
                transition: all .2s ease-in-out
                box-shadow: 0 3px 6px rgba(0, 0, 0, .16)
                border-left: 2px solid rgba(0, 0, 0, .25)
                border-right: 2px solid rgba(0, 0, 0, .25)
                border-top: 2px solid rgba(0, 0, 0, .25)
                border-bottom: 2px solid rgba(0, 0, 0, .25)
                &:hover
                    box-shadow: 0 3px 6px rgba(0, 0, 0, .36)
                &.selected-horizontal-card
                    border-left: 2px solid rgba(0, 0, 0, .5)
                    border-right: 2px solid rgba(0, 0, 0, .5)
                    border-top: 2px solid rgba(0, 0, 0, .5)
                    border-bottom: 2px solid rgba(0, 0, 0, .5)
    
    .no-image
        background: rgba(0, 0, 0, .1)
        .q-icon
            color: rgba(0, 0, 0, .15)
            font-size: 2.5rem
            line-height: 2.5rem

    .hidden-calendar 
        opacity: 0
        pointer-events: none
        position: fixed
    
    .selected-service-locations
        .q-field__inner
            padding: 0
    
    .q-calendar-daily__head
        margin-right: 0!important
    .q-calendar-daily__scroll-area
        overflow: overlay
    .q-calendar-daily__scroll-area::-webkit-scrollbar
        width: 10px
    .q-calendar-daily__scroll-area::-webkit-scrollbar 
        *
            background: rgba(0, 0, 0, 0)
    .q-calendar-daily__scroll-area::-webkit-scrollbar-thumb
        background: var(--q-color-light)!important
    .q-calendar-daily__scroll-area::-webkit-scrollbar-thumb:hover
        background: var(--q-color-grey)!important

    .calendar-container.appointment .q-calendar-weekly.month .q-badge
        visibility: visible
  </style>
